@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700&family=Poppins:wght@200;300;400;500;600;700&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #101d2b;
  font-family: "Nunito", sans-serif;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-size: 16px;
  line-height: 23px;
  color: white;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.top {
  width: 100%;
  height: 145px;
  background-color: #101d2b;
  border-bottom: 3px solid #b68e63;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.top img {
  height: 70%;
  width: auto;
  object-fit: contain;
}

header {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, #1717171f, #171717e0),
    url("/src/images/bannerimage.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  display: flex;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

header h3 {
  margin: 0 20px;
  color: white;
  font-size: 21px;
  text-transform: uppercase;
}

header h3:first-child {
  animation: startingAnimationTwo 1s forwards;
}

@keyframes startingAnimationTwo {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

header h3:nth-child(3) {
  animation: startingAnimationSubtitle 2.5s forwards;
}

@keyframes startingAnimationSubtitle {
  0% {
    opacity: 0;
    margin-bottom: -250px;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    margin-bottom: 0;
  }
}

header h1 {
  color: white;
  font-size: 58px;
  line-height: 52px;
  margin: 20px;
  opacity: 0;
  animation: startingAnimationH1 1.5s forwards;
}

@keyframes startingAnimationH1 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

header a {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  border-radius: 100%;
  border: 1px solid white;
  padding: 10px;
  animation: arrowAnimation 4s infinite ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes arrowAnimation {
  0% {
    bottom: 40px;
  }
  50% {
    bottom: 10px;
  }
  100% {
    bottom: 40px;
  }
}

.footertext {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 10px;
  background-color: #171717;
  border-bottom: 1px solid #404148;
  border-top: 3px solid #b68e63;
  padding-bottom: 50px;
  width: calc(100% - 20px);
}

.footertextblock p {
  font-size: 16px;
  color: white;
  margin: 5px 0;
  max-width: 900px;
}

.footertextblock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 10px;
}

.footertextblock h2 {
  color: #b68e63;
  margin: 35px 0;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 300;
}

.footertextblock a {
  color: white;
  text-decoration: none;
  margin: 5px 0;
  font-weight: 300;
  transition: 0.3s all ease-in-out;
}

.footertextblock span {
  color: white;
  text-decoration: none;
  margin: 5px 0;
  font-weight: 300;
  transition: 0.3s all ease-in-out;
}

.footertextblock a:hover {
  color: #b68e63;
}

@media (max-width: 990px) {
  .top {
    height: 100px !important;
  }
  .top img {
    height: 80%;
  }
  header {
    height: calc(100vh - 100px);
    background-position: left;
  }
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 50px;
  width: 100%;
}

main h2 {
  color: #b68e63;
  font-size: 21px;
  max-width: 90%;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}

main h1 {
  color: #ffffff;
  font-size: 35px;
  max-width: 90%;
  text-align: center;
  line-height: 35px;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 40px;
}

main .textblock {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 25px 20px;
  padding: 50px 0;
  max-width: 1300px;
}

main .textblock.reverse {
  flex-direction: row-reverse;
}

main .textblock.reverse .textarea {
  margin-left: 0;
  margin-right: 50px;
}

main .textblock .textarea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
  margin-left: 50px;
}

main .textblock img {
  width: 50%;
  height: 300px;
  object-fit: cover;
}

main .textblock h2 {
  color: #b68e63;
  /* margin-top: 35px; */
  font-size: 21px;
  margin-bottom: 15px;
}

main .textblock p {
  color: white;
  margin: 0;
  text-align: start;
}

main .textbaustein {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  max-width: 1300px;
  margin-bottom: 25px;
  background-color: #b68e63;
}

main .textbaustein h1 {
  margin-bottom: 0;
  text-align: left;
  line-height: 35px;
}

main .textbaustein h2 {
  text-align: left;
  color: #171717;
}

.fade-in-element {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s, transform 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.fade-in-element.visible {
  opacity: 1;
  transform: translateY(0);
}

.marked {
  text-decoration: underline;
  text-decoration-color: #b68e63;
  /* background-color: #b68e63;
  padding: 0 10px; */
}

.privacy {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #171717;
  padding-top: 10px;
  z-index: 1000;
  overflow: auto;
}

.privacycontent {
  flex: 1;
  background-color: #171717;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.privacycontent h2 {
  color: #b68e63;
  font-size: 21px;
  max-width: 100%;
  text-align: left;
  text-transform: uppercase;
}

.privacycontent p {
  text-align: left;
  color: white;
  max-width: 100%;
  margin: 0;
}

.privacy span {
  position: absolute;
  top: 20px;
  right: 20px;
}

.privacy span:hover {
  cursor: pointer;
}

.lang {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
}

.lang img {
  height: 40px;
  width: auto;
}

.contactoptions {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  margin: 20px 0;
  max-width: 600px;
}

.contactoption {
  width: 250px;
  height: 250px;
  max-width: 550px;
  background-color: #282828;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid grey;
  border-radius: 15px;
  text-decoration: none;
}

.banner {
  width: 100%;
  height: 450px;
  position: relative;
}

.banner .innertextblock {
  position: absolute;
  bottom: -100px;
  right: 100px;
  width: 500px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.banner .innertextblock span {
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: bold;
}

.banner .innertextblock h2 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 32px;
  text-align: start;
}

.banner .innertextblock ul {
  padding: 0;
  margin: 0;
}

.banner .innertextblock ul li {
  font-size: 16px;
  color: #6a6868;
  list-style: none;
  margin-bottom: 10px;
  font-weight: bold;
  position: relative;
  padding-left: 20px;
}

.banner .innertextblock ul li::before {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  background-color: #b68e63;
  border-radius: 1000px;
}

.banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom: 3px solid #b68e63;
  border-top: 3px solid #b68e63;
}

@media (max-width: 990px) {
  main .textblock h2 {
    text-align: left;
  }

  .contactoptions {
    margin: 0;
  }
  .contactoption {
    width: 100%;
    border-radius: 0;
    border: none;
  }
  .contactoption:nth-child(2) {
    border-top: 2px solid grey;
  }

  .textblock {
    flex-direction: column;
    align-items: center !important;
    max-width: 100% !important;
    padding: 30px 0 !important;
  }

  .textblock.reverse {
    flex-direction: column !important;
    max-width: 100% !important;
  }

  .textblock .textarea {
    margin-left: 0 !important;
    margin-top: 30px;
    width: 90% !important;
  }

  .textblock.reverse .textarea {
    margin-right: 0 !important;
    margin-top: 30px;
  }

  main .textblock img {
    width: 90%;
  }

  .banner {
    margin-bottom: 100px !important;
  }

  .banner .innertextblock {
    left: 50%;
    transform: translate(-50%, 0);
    width: 80%;
  }
}

.contactoption p {
  font-weight: 600;
  margin: 0;
  color: #ffffff;
}

.contact-form {
  max-width: 1000px;
  padding: 10px 40px;
  margin: 0 auto;
  border-radius: 8px;
  margin-bottom: 75px;
  color: white;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  resize: none;
  outline: none;
}

.form-group textarea {
  height: 100px;
}

.submit-btn {
  padding: 10px 15px;
  background-color: #b68e63;
  color: white;
  border: none;
  cursor: pointer;
}

@media (max-width: 900px) {
  .contact-form {
    padding: 10px 40px;
    max-width: 100%;
  }

  .form-group input,
  .form-group textarea {
    padding: 12px 10px;
    font-size: 14px;
    width: calc(100% - 20px);
  }
}
